import moment from 'moment';
import ServerSideAuth from './ServerSideAuth';
import QuickLRU from 'quick-lru';
import { Where, WhereField } from 'payload/types';
import { EventItem, PayloadTypes } from '@messe-sp/payloadcms/types';
import type { Payload } from 'payload';

const eventCache = new QuickLRU<unknown, Array<PayloadTypes.EventItem>>({
    maxSize: 1000,
});

const userCache = new QuickLRU<string, any>({
    maxSize: 1000,
    maxAge: 1000 * 60 * 5,
});

const pageCache = new QuickLRU<string, any>({
    maxSize: 1000,
    maxAge: 1000 * 10, // 10 seconds
});

export type EventSearchFilter = {
    filterMode: 'filter' | 'ids' | 'none';
    year?: number;
    location: 'All' | 'Frankfurt' | 'International';
    withOwnContent?: boolean;
    industries?:
        | Array<{
              id: string;
          }>
        | string[];
    eventIds?:
        | Array<{
              id: string;
          }>
        | string[];
};

const createEventFilterQueryString = (
    filter: Partial<EventSearchFilter> = {},
    searchQueryText = '',
    pageSize = 25,
    pageIndex = 0,
) => {
    let searchQuery = `?_start=${pageSize * pageIndex}`;
    searchQuery += `&_limit=${pageSize}`;

    const localLocale = moment();
    localLocale.locale('en');
    searchQuery += `&visibleUntil_gte=${localLocale.format('YYYY-MM-DD')}`;

    if (searchQueryText) {
        searchQuery += '&eventname_contains=';
        searchQuery += searchQueryText;
    }

    if (filter) {
        let filterMode = 'filter';
        if (filter.filterMode) {
            filterMode = filter.filterMode;
        }

        if (filterMode === 'filter') {
            filter.industries?.forEach((industry) => {
                searchQuery += '&clusterMarketing_in=';
                searchQuery += encodeURIComponent(
                    typeof industry === 'string'
                        ? industry
                        : industry.id.replace('&', '%26'),
                );
            });

            if (filter.year)
                searchQuery += `&eventyear_eq=${filter.year.toString()}`;

            if (filter.location && filter.location !== 'All') {
                if (filter.location === 'Frankfurt') {
                    searchQuery += '&cityname_eq=Frankfurt+am+Main';
                } else {
                    searchQuery += '&cityname_ne=Frankfurt+am+Main';
                }
            }
        } else if (filterMode === 'ids') {
            if (filter.eventIds) {
                for (const eventId of filter.eventIds) {
                    searchQuery += '&eventid_in=';
                    searchQuery +=
                        typeof eventId === 'string' ? eventId : eventId.id;
                }
            }
        }

        if (filter.withOwnContent) {
            searchQuery += '&ownContent=true';
        }
    }

    if (!searchQuery) searchQuery = '';

    return searchQuery;
};

const fetchEvents = async (apiUrl: string, filterAsString: string) => {
    const data = await fetch(`${apiUrl}/eventsearch${filterAsString}`);

    return data.json();
};

const EventSearchUtil = {
    createEventFilterQueryString,
    fetchEvents,
};

export default EventSearchUtil;
