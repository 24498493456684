import React from 'react';
import { EventSearchItem } from '../../../types/EventSearchItem';
import { useSiteData } from '../../../providers/SiteDataProvider';
import Moment from 'react-moment';

const EventItem: React.FC<{
    item: EventSearchItem;
    langKey: string;
    right?: boolean;
}> = ({ item, langKey, right }) => {
    const { t, cookiesAllowed } = useSiteData();

    // FIXME
    const eventPages = undefined;
    const startDate = new Date(item.startdate);
    const endDate = new Date(item.enddate);

    if (item.interneturl && item.interneturl === '-') debugger;

    let className = 'm-search-result-item--event ex-event-search-result-item';
    if (right) {
        className += ' ex-event-search-result-item--right';
    }

    const eventItem = item;
    const endsInOtherMonth =
        eventItem.startdate &&
        eventItem.enddate &&
        eventItem.startdate.substring(5, 7) !== eventItem.enddate.substr(5, 7);
    let dateEndClass = 'ex-event-search-result-item__date-end-container';
    if (endsInOtherMonth) dateEndClass += ' text-right';

    let intro = undefined;

    if (eventItem.content && eventPages) {
        const page = eventPages[eventItem.eventnumber];
        if (page && page[langKey]) {
            intro = page[langKey];
        }
    }

    return (
        <div className={className}>
            <div className="ex-event-search-result-item__content">
                <div className="ex-event-search-result-item__image">
                    <div className="a-image">
                        <figure>
                            <img
                                alt=""
                                className="img-responsive ex-event-search-result-item__event-image"
                                src={
                                    cookiesAllowed &&
                                    item.logoUrl &&
                                    item.logoUrl.length > 0
                                        ? item.logoUrl
                                        : '/content/placeholder-event.png'
                                }
                            />
                        </figure>
                    </div>
                </div>
                <div className="ex-event-search-result-item__location-container text-muted">
                    <span className="ex-event-search-result-item__location-country">
                        {eventItem.iso3}
                    </span>
                    <span className="ex-event-search-result-item__location-city">
                        {' '}
                        {eventItem.cityname}
                    </span>
                    {!eventItem.clusterMarketing ||
                    eventItem.clusterMarketing.length === 0 ? null : (
                        <span className="ex-event-search-result-item__location-cluster">
                            {' '}
                            {eventItem.clusterMarketing}
                        </span>
                    )}
                </div>
                <div className="ex-event-search-result-item__date-text-container">
                    <div className="ex-event-search-result-item__date-container-mobile">
                        <span className="h2 ex-event-search-result-item__date-startday">
                            <Moment format="DD" trim="false">
                                {startDate}
                            </Moment>
                        </span>
                        <span className="h4 ex-event-search-result-item__date-startmonth text-muted hidden" />
                        <span className="h4 ex-event-search-result-item__date-startyear text-muted hidden" />
                        <span className="h2 ex-event-search-result-item__date-separator">
                            –
                        </span>
                        <span className="h2 ex-event-search-result-item__date-endday">
                            <Moment format="DD" trim="false">
                                {endDate}
                            </Moment>
                        </span>
                        <span className="h4 ex-event-search-result-item__date-endmonth text-muted">
                            <Moment format="MMM" trim="false">
                                {startDate}
                            </Moment>
                        </span>
                        <span className="h4 ex-event-search-result-item__date-endyear text-muted">
                            &apos;<Moment format="YY">{startDate}</Moment>
                        </span>
                    </div>
                    <div className="ex-event-search-result-item__date-container-desktop">
                        <div className="ex-event-search-result-item__date-start-container">
                            {!endsInOtherMonth ? null : (
                                <React.Fragment>
                                    <span className="h4 ex-event-search-result-item__date-startmonth text-muted">
                                        <Moment format="MMM" trim="false">
                                            {startDate}
                                        </Moment>
                                    </span>
                                    <span className="h4 ex-event-search-result-item__date-endyear text-muted">
                                        &apos;
                                        <Moment format="YY">{startDate}</Moment>
                                    </span>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="ex-event-search-result-item__date-days-container">
                            <span className="h2 ex-event-search-result-item__date-startday">
                                <Moment format="DD" trim="false">
                                    {startDate}
                                </Moment>
                            </span>
                            <span className="h2 ex-event-search-result-item__date-separator">
                                –
                            </span>
                            <span className="h2 ex-event-search-result-item__date-endday">
                                <Moment format="DD" trim="false">
                                    {endDate}
                                </Moment>
                            </span>
                        </div>
                        <div className={dateEndClass}>
                            <span className="h4 ex-event-search-result-item__date-endmonth text-muted">
                                <Moment format="MMM">
                                    {endDate || startDate}
                                </Moment>
                            </span>
                            <span className="h4 ex-event-search-result-item__date-endyear text-muted">
                                &apos;
                                <Moment format="YY">
                                    {endDate || startDate}
                                </Moment>
                            </span>
                        </div>
                    </div>
                    <div className="ex-event-search-result-item__text-container">
                        {item.interneturl && item.interneturl.length > 5 ? (
                            <a
                                className="h3 ex-event-search-result-item__headline"
                                href={item.interneturl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span>{eventItem.eventname}</span>
                            </a>
                        ) : (
                            <span className="h3 ex-event-search-result-item__headline">
                                {eventItem.eventname}
                            </span>
                        )}

                        {!intro ? null : (
                            <p className="ex-event-search-result-item__copy">
                                <span
                                    dangerouslySetInnerHTML={{ __html: intro }}
                                />
                            </p>
                        )}

                        {typeof eventItem.page === 'undefined' ||
                        !eventItem.page ? null : (
                            <p className="ex-event-search-result-item__copy">
                                <a
                                    target="_blank"
                                    href={`/${langKey}/event/${eventItem.page}`}
                                    rel="noreferrer"
                                >
                                    <span className="ex-event-search-result-item__calendar-text">
                                        {t('More information')}
                                    </span>
                                </a>
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventItem;
