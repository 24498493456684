import React, { useEffect, useState } from 'react';
import { useSiteData } from '../../../providers/SiteDataProvider';
import EventFilter from './EventFilter';
import { EventSearchFilter } from '../../../util/EventSearchUtil';

const EventSearch: React.FC<{
    filter: EventSearchFilter;
    onFilterChanged: () => void;
    onSearchTextChange: (val: string) => void;
}> = ({ filter, onFilterChanged, onSearchTextChange }) => {
    const { t } = useSiteData();
    const [searchQueryTemp, setSearchQueryTemp] = useState('');

    const handleFilterChange = () => {
        onFilterChanged();
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQueryTemp(e.target.value);
    };

    const handleSearchClick = () => {
        onSearchTextChange(searchQueryTemp);
    };

    const handleSearchKeyDown = () => {
        onSearchTextChange(searchQueryTemp);
    };

    return (
        <div className="ex-search-form">
            <div className="row">
                <div className="col-xs-12 col-sm-10 col-md-9">
                    <div className="m-form a-form-input a-form-input--searchfield">
                        <div className="ex-input-autosuggest">
                            <input
                                type="text"
                                className="ex-search-form__searchbar form-control"
                                name="q"
                                onChange={handleSearchChange}
                                placeholder={t('Enter an event name.')}
                                value={searchQueryTemp}
                                onKeyDown={handleSearchKeyDown}
                            />
                        </div>
                        <div
                            className="ex-search-form__submit btn btn-primary btn-icon-single btn-icon-single-xxs"
                            onClick={handleSearchClick}
                        >
                            <span className="icon icon-magnifier" />
                            <span className="btn-label btn-label-hidden-xxs">
                                {t('Search')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <EventFilter filter={filter} onChange={handleFilterChange} />
        </div>
    );
};

export default EventSearch;
