import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { EventSearchFilter } from '../../../util/EventSearchUtil';
import { useSiteData } from '../../../providers/SiteDataProvider';
import Select from 'react-select';

const newDateObj = moment(new Date()).add(18, 'M').toDate();
const firstYear = moment(new Date()).year();
const lastYear = newDateObj.getFullYear();

const eventYears: Array<number> = [];
for (let i = firstYear; i <= lastYear; i++) {
    eventYears.push(i);
}
type IndustrySelectionItem = {
    value: string;
    label: string;
};

const EventFilter: React.FC<{
    filter: EventSearchFilter;
    onChange: (filter: EventSearchFilter) => void;
}> = ({ filter, onChange }) => {
    const { t, eventClusters } = useSiteData();

    const industries = useMemo<IndustrySelectionItem[]>(
        () =>
            eventClusters.map((item) => ({
                label: item,
                value: item,
            })),
        [eventClusters],
    );

    const handleFilterChanged = () => {
        onChange(filter);
    };

    const handleClustersChange = (selectedClusters?: string[]) => {
        filter.industries = selectedClusters;
        handleFilterChanged();
    };

    const handleLocationChange = (location: EventSearchFilter['location']) => {
        filter.location = location;
        handleFilterChanged();
    };

    const handleYearChange = (year: number | null) => {
        if (year) {
            filter.year = year;
        } else {
            filter.year = undefined;
        }
        handleFilterChanged();
    };

    return (
        <div className="mo-event-filter">
            <div className="row">
                <div className="col-xs-12 col-sm-10 col-md-9">
                    <Select
                        className="mo-event-filter-dropdown"
                        classNamePrefix="mo-event-filter-dropdown"
                        placeholder={t('All industries')}
                        isMulti={true}
                        value={filter?.industries?.map((it) => {
                            if (typeof it === 'string') {
                                return {
                                    label: it,
                                    value: it,
                                };
                            } else {
                                return {
                                    label: it.id,
                                    value: it.id,
                                };
                            }
                        })}
                        onChange={(values) => {
                            handleClustersChange(
                                values
                                    ?.filter((it) => !!it?.value)
                                    .map((it) => it!.value),
                            );
                        }}
                        options={industries}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-10 col-md-9">
                    <div className="radiobuttons abstractComponent">
                        <ul className="list-inline">
                            <li
                                className="a-form-input a-form-input--radio"
                                data-t-name="FormInput"
                                data-t-id="14"
                            >
                                <input
                                    type="radio"
                                    id="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_0"
                                    name="Location"
                                    value="All"
                                    checked={
                                        !filter?.location ||
                                        filter.location === 'All'
                                    }
                                    onChange={() => handleLocationChange('All')}
                                />
                                <label htmlFor="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_0">
                                    {t('All events')}
                                </label>
                            </li>

                            <li
                                className="a-form-input a-form-input--radio"
                                data-t-name="FormInput"
                                data-t-id="15"
                            >
                                <input
                                    type="radio"
                                    id="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_1"
                                    name="Location"
                                    value="Frankfurt"
                                    checked={filter.location === 'Frankfurt'}
                                    onChange={() =>
                                        handleLocationChange('Frankfurt')
                                    }
                                />
                                <label htmlFor="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_1">
                                    {t('Events in Frankfurt')}
                                </label>
                            </li>
                            <li
                                className="a-form-input a-form-input--radio"
                                data-t-name="FormInput"
                                data-t-id="165"
                            >
                                <input
                                    type="radio"
                                    id="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_2"
                                    name="Location"
                                    value="International"
                                    checked={
                                        filter.location === 'International'
                                    }
                                    onChange={() =>
                                        handleLocationChange('International')
                                    }
                                />
                                <label htmlFor="6e633992ae15be05e24f701b3bce5b862d025e81860a22b32759385593f78ae8_2">
                                    {t('Events worldwide')}
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="row">
                <form className="m-search-filter m-search-filter--options">
                    <div className="m-search-filter__select-option">
                        <input
                            type="radio"
                            data-label={t('All years')}
                            id="optionAll"
                            name="year"
                            value=""
                            onChange={() => handleYearChange(null)}
                        />
                        <label
                            className={
                                !filter.year
                                    ? 'm-search-filter__item--active'
                                    : ''
                            }
                            htmlFor="optionAll"
                        >
                            <span>{t('All years')}</span>
                        </label>

                        {eventYears.map((year, idx) => {
                            const yearItem =
                                filter.year === year ? (
                                    <label
                                        className="m-search-filter__item--active"
                                        htmlFor={`option-year-${year}`}
                                    >
                                        <span>{year}</span>
                                    </label>
                                ) : (
                                    <label htmlFor={`option-year-${year}`}>
                                        <a>{year}</a>
                                    </label>
                                );
                            return (
                                <React.Fragment key={idx}>
                                    <input
                                        type="radio"
                                        id={`option-year-${year}`}
                                        name="year"
                                        value={year}
                                        onChange={() => handleYearChange(year)}
                                    />
                                    {yearItem}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EventFilter;
