import React, { useEffect, useState } from 'react';
import { EventSearchItem } from '../../../types/EventSearchItem';
import EventItem from './EventItem';
import { useSiteData } from '../../../providers/SiteDataProvider';

export const EventsOverview: React.FC<{
    className?: string;
    eventItems: EventSearchItem[];
    isTeaser?: boolean;
}> = ({ eventItems, className }) => {
    const { currentLanguage } = useSiteData();

    return (
        <div className={className || 'ex-event-search-results'}>
            <div>
                <div>
                    {eventItems?.length
                        ? eventItems.map((event, index) => {
                              return (
                                  <EventItem
                                      langKey={currentLanguage}
                                      item={event}
                                      key={event.id}
                                      right={(index + 1) % 2 === 0}
                                  />
                              );
                          })
                        : ''}
                </div>
            </div>
        </div>
    );
};
