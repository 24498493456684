import React, { useEffect, useState } from 'react';
import { BaseModuleProps, StrapiPageType } from '../../../types';
import { useSiteData } from '../../../providers/SiteDataProvider';
import EventSearchUtil, {
    EventSearchFilter,
} from '../../../util/EventSearchUtil';
import { EventSearchItem } from '../../../types/EventSearchItem';
import { EventsOverview } from './EventsOverview';

import EventSearch from './EventSearch';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useIntersection } from 'react-use';
import { PageTypes } from '@messe-sp/data/types';

const Events: React.FC<
    BaseModuleProps & {
        pageContent: {
            isTeaser?: boolean;
            events?: Array<unknown>;
            filter?: EventSearchFilter;
            maxCount?: number;
        };
    }
> = ({ moduleHead, pageContent }) => {
    const { isTeaser, filter: initialFilter, maxCount } = pageContent;
    const [loading, setLoading] = useState(true);

    const router = useRouter();
    const { eventApiUrl, t, getSpecialLink } = useSiteData();
    const [searchQuery, setSearchQuery] = useState('');
    const [eventItems, setEventItems] = useState<Array<EventSearchItem>>([]);
    const [initialized, setInitialized] = useState(
        typeof window === 'undefined',
    );

    const getFilterOnRender = (): EventSearchFilter => {
        const qs = router.query;
        const baseFilter: EventSearchFilter = initialFilter || {
            location: 'All',
            filterMode: 'filter',
        };

        if (qs) {
            if (qs.filter_industries) {
                baseFilter.industries = qs.filter_industries
                    .toString()
                    .split(',')
                    .map((item) => ({
                        id: item.replace(/;/g, ',').replace(/%26/g, '&'),
                    }));
            }

            if (qs.filter_location) {
                baseFilter.location =
                    qs.filter_location as typeof baseFilter['location'];
            }

            if (qs.filter_industries || qs.filter_location) {
                // remove default events because of querystring
            }
        }
        return baseFilter;
    };

    const [filter, setFilter] = useState<EventSearchFilter>(() =>
        getFilterOnRender(),
    );

    const refresh = () => {
        const filterQueryString = EventSearchUtil.createEventFilterQueryString(
            filter,
            searchQuery,
            isTeaser ? maxCount || 3 : 10000,
        );
        setLoading(true);
        EventSearchUtil.fetchEvents(eventApiUrl, filterQueryString).then(
            (results) => {
                setEventItems(results.items);
                setLoading(false);
            },
        );
    };

    useEffect(() => {
        refresh();
    }, [maxCount]);

    const intersectionRef = React.useRef(null);
    const intersection = useIntersection(intersectionRef, {
        rootMargin: '100px',
        threshold: 0.1,
    });

    useEffect(() => {
        console.log(`loading ${loading}`);
    }, [loading]);
    useEffect(() => {
        if (!loading) {
            if (eventItems?.length && intersection) {
                console.log(
                    'intersection ',
                    intersection.boundingClientRect,
                    intersection.intersectionRect,
                );
                if (intersection.boundingClientRect.y > 0) {
                }
            }
        }
    }, [intersection, eventItems, loading]);

    useEffect(() => {
        if (!document.getElementById('eventsearch-css')) {
            const appendStylesheet = (href: string) => {
                return new Promise<void>((resolve) => {
                    const el = document.createElement('link');
                    el.href = href;
                    el.rel = 'stylesheet';
                    el.onload = () => {
                        resolve();
                    };
                    document.head.appendChild(el);
                });
            };
            Promise.all([
                appendStylesheet(
                    'https://salespartner.messefrankfurt.com/styles/eventsearch.css',
                ),
                appendStylesheet(
                    'https://salespartner.messefrankfurt.com/styles/eventsearchslim.css',
                ),
            ]).then(() => {
                setInitialized(true);
            });
        }
    }, []);
    useEffect(() => {
        refresh();
    }, [searchQuery]);

    if (!initialized) return null;

    const getEventSearchLink = () => {
        const baseLink = getSpecialLink('Events')?.href;
        if (
            baseLink &&
            filter &&
            (!filter.filterMode || filter.filterMode === 'filter')
        ) {
            const filters: Array<string> = [];
            if (filter.industries) {
                const indFilter: Array<string> = [];
                for (const industry of filter.industries) {
                    indFilter.push(
                        encodeURI(
                            typeof industry === 'string'
                                ? industry
                                : industry.id,
                        )
                            .replace(/,/g, ';')
                            .replace(/&/g, '%26'),
                    );
                }
                const induFilter = indFilter.join(',');

                filters.push('filter_industries=' + induFilter);
            }
            if (filter.location) {
                filters.push('filter_location=' + filter.location);
            }

            return baseLink + '?' + filters.join('&');
        }
        return baseLink;
    };

    return (
        <>
            <div
                className={
                    isTeaser
                        ? 'eventSearchSlimPrefiltered eventSearchSlim abstractComponent'
                        : 'eventSearch abstractComponent'
                }
            >
                {moduleHead}
                <div className="">
                    <div id="mf-ev-root">
                        <div data-reactroot="" className="ex-application">
                            <div>
                                <div>
                                    <div
                                        className={
                                            isTeaser
                                                ? 'o-search-results-container o-search-results-container--eventsearchslim ev-event-search-slim'
                                                : 'ex-event-search'
                                        }
                                    >
                                        {!isTeaser && (
                                            <EventSearch
                                                filter={filter}
                                                onFilterChanged={refresh}
                                                onSearchTextChange={
                                                    setSearchQuery
                                                }
                                            />
                                        )}

                                        <div>
                                            <div
                                                className={
                                                    !isTeaser
                                                        ? 'ex-event-search__container h-background h-background--fullwidth ex-event-search__container_narrow'
                                                        : 'o-search-results-container__results h-background h-background--fullwidth'
                                                }
                                            >
                                                <EventsOverview
                                                    eventItems={eventItems}
                                                    isTeaser={isTeaser}
                                                />
                                                {!isTeaser && (
                                                    <div
                                                        ref={intersectionRef}
                                                    />
                                                )}

                                                {/*      <EventItems

                                                langKey={this.lang_key}
                                                isTeaser={isTeaser}
                                                filter={this.state.filter}
                                                events={ this.state.events }
                                                searchQuery={ this.state.searchQuery }
                                            />*/}
                                            </div>
                                        </div>

                                        {isTeaser && (
                                            <div className="o-search-results-container__more-results h-background h-background--fullwidth">
                                                <p>
                                                    <Link
                                                        className="a-link a-link--single-internal-left"
                                                        href={getEventSearchLink()}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        {t(
                                                            'Go to event search',
                                                        )}
                                                    </Link>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Events;
